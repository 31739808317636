import React from 'react';
import { Button, Divider, Link } from '@mui/material';
import TinkoffButton from './Tinkoff';
import { selectLanguage } from '../../../redux/profileDataSlice';
import { useAppSelector } from '../../../utils/hooks';
import { isEmptyObject } from '../../../utils/ObjectUtils';
import splash from '../../../static/splash.png';
import logo from '../../../static/logo.png';
import styles from './Hello.module.css';

import googleBadge from '../../../static/googlePlayBadge.svg';
import appStoreBadge from '../../../static/appStoreBadge.svg';
import ruStoreBadge from '../../../static/ruStoreBadge.svg';

interface Interface {
  onSelectAuth: (type: string) => void;
}

const appStoreHref = 'https://apps.apple.com/app/id6444651677';
const googlePlayHref =
  'https://play.google.com/store/apps/details?id=com.askan.gwp_client_application';
const ruStoreHref = 'https://www.rustore.ru/catalog/app/com.askan.gwp_client_application';


const Hello = ({ onSelectAuth }: Interface) => {
  const language = useAppSelector(selectLanguage);

  return (
    <>
      <img src={splash} alt="" style={{ width: '100%', objectFit: 'contain' }} />
      <div className={styles.logo}>
        <img src={logo} alt="" />
      </div>
      {isEmptyObject(language) ? null : (
        <>
          <div className={styles.text}>{language['APPEAL_GWP']}</div>
          <div className={`${styles.text} ${styles.text_apeal}`}>{language['DOWNLOAD_APPEAL']}</div>
          <div className={styles.buttons}>
            <Link href={appStoreHref} classes={{ root: styles.tin_id_badge }}>
              <img src={appStoreBadge} className={styles.tin_id_icon} alt="Download from AppStore" />
            </Link>
            <Link href={googlePlayHref} classes={{ root: styles.tin_id_badge }}>
              <img src={googleBadge} className={styles.tin_id_icon} alt="Download from Google play" />
            </Link>
            <Link href={ruStoreHref} classes={{ root: styles.tin_id_badge }}>
              <img src={ruStoreBadge} className={styles.tin_id_icon} alt="RuStore" />
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default Hello;
