import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfileModel, UserProfileUpdateModel } from '../models';
import httpClient from '../api/http-client';
import { fetchToken, fetchTokenViaCode, refreshToken, revokeToken, TokenResponse } from '../api';
import { AppDispatch, RootState } from './store';
import { getLocalAuth, parseJwt } from '../utils/auth';
import { PhoneState } from '../utils/Constants';

interface InitialState {
  loading: boolean;
  data: UserProfileModel | null;
  userUuid: string;
  loadingLanguage: boolean;
  language: { [key: string]: string };
  comment_language: { [key: string]: string };
  desc_language: { [key: string]: string };
  currentLang: string;
  loadingToken: boolean;
  token: TokenResponse | null;
  loadingCheckPhone: boolean;
  tokenError: string;
}

const REDIRECT_ID = `${process.env.REACT_APP_API_HOST}/login`;

export const profileDataSlice = createSlice({
  name: 'profileData',
  initialState: {
    loading: true,
    data: null,
    userUuid: '',
    loadingLanguage: false,
    language: {},
    comment_language: {},
    desc_language: {},
    currentLang: '',
    loadingToken: false,
    token: getLocalAuth(),
    loadingCheckPhone: false,
    tokenError: '',
  } as InitialState,
  reducers: {
    setData: (state, action: PayloadAction<UserProfileModel | null>) => {
      state.data = action.payload;
    },
    setUserUuid: (state, action: PayloadAction<string>) => {
      state.userUuid = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLanguage: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.language = action.payload;
    },
    setCommentLanguage: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.comment_language = action.payload;
    },
    setDescLanguage: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.desc_language = action.payload;
    },
    setLoadingLanguage: (state, action: PayloadAction<boolean>) => {
      state.loadingLanguage = action.payload;
    },
    setCurrentLang: (state, action: PayloadAction<string>) => {
      state.currentLang = action.payload;
    },
    setToken: (state, action: PayloadAction<TokenResponse | null>) => {
      state.token = action.payload;
    },
    setLoadingToken: (state, action: PayloadAction<boolean>) => {
      state.loadingToken = action.payload;
    },
    setLoadingCheckPhone: (state, action: PayloadAction<boolean>) => {
      state.loadingCheckPhone = action.payload;
    },
    setTokenError: (state, action: PayloadAction<string>) => {
      state.tokenError = action.payload;
    },
  },
});

export const {
  setData,
  setUserUuid,
  setLoading,
  setLoadingLanguage,
  setLanguage,
  setCommentLanguage,
  setDescLanguage,
  setCurrentLang,
  setLoadingToken,
  setToken,
  setLoadingCheckPhone,
  setTokenError,
} = profileDataSlice.actions;

export const loadUserProfileAsync = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await httpClient.get<UserProfileModel>(`user/profile`);
    dispatch(setData(response.data));
    return response.data;
  } catch (e: any) {
    console.log('loadUserProfileAsync', e.message);
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateUserProfileAsync =
  (data: UserProfileUpdateModel) => async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.post(`user/profile`, data);
      if (response.status === 200) {
        await dispatch(loadUserProfileAsync());
        return true;
      } else {
        return false;
      }
    } catch (e: any) {
      console.log('updateUserProfileAsync', e.message);
      return false;
    }
  };

export const loadLanguageAsync =
  (langCode: string | null, signal?: AbortSignal) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const lang = langCode || selectCurrentLang(getState()) || 'ru';
    dispatch(setLoadingLanguage(true));

    try {
      const response = await httpClient.get(`dictionary/int_el?lang=${lang}`, { signal });
      dispatch(setLanguage(response.data));
      localStorage.setItem(`int_el_${lang}`, JSON.stringify(response.data));
    } catch (e) {
      const int_el = localStorage.getItem(`int_el_${lang}`);
      if (int_el) {
        dispatch(setLanguage(JSON.parse(int_el)));
      } else {
        switch (lang) {
          case 'ru':
            dispatch(
              setLanguage({
                WELC_GWL: 'Добро пожаловать в бизнес залы Grey Wall!',
                LOGIN_PH_NUM: 'Войти по номеру телефона',
                APPEAL_GWP: 'Получите доступ к более 1000+ залам по всему миру!',
                DOWNLOAD_APPEAL: "Скачивайте приложение Grey Wall Pass и присоединяйтесь к нашей программе лояльности."
              })
            );
            break;
          case 'en':
          default:
            dispatch(
              setLanguage({
                WELC_GWL: 'Welcome to Gray Wall Business Lounges!',
                LOGIN_PH_NUM: 'Log in with phone number',
                APPEAL_GWP: 'Get access to over 1000+ lounges around the world!',
                DOWNLOAD_APPEAL: "Download the Grey Wall Pass app and join our loyalty programme."
              })
            );
        }
      }
    }

    try {
      const comment_response = await httpClient.get(`dictionary/lt_attr_comment?lang=${lang}`);
      dispatch(setCommentLanguage(comment_response.data));
      localStorage.setItem(`lt_attr_comment_${lang}`, JSON.stringify(comment_response.data));
    } catch (e) {
      const lt_attr_comment = localStorage.getItem(`lt_attr_comment_${lang}`);
      dispatch(setDescLanguage(lt_attr_comment ? JSON.parse(lt_attr_comment) : {}));
    }

    try {
      const desc_response = await httpClient.get(`dictionary/lt_attr_desc?lang=${lang}`);
      dispatch(setDescLanguage(desc_response.data));
      localStorage.setItem(`lt_attr_desc_${lang}`, JSON.stringify(desc_response.data));
    } catch (e) {
      const lt_attr_desc = localStorage.getItem(`lt_attr_desc_${lang}`);
      dispatch(setDescLanguage(lt_attr_desc ? JSON.parse(lt_attr_desc) : {}));
    }

    dispatch(setLoadingLanguage(false));
  };

export const checkUserPhoneAsync = (phone: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoadingCheckPhone(true));
  try {
    const response = await httpClient.get(`user/state?phone=${phone}`);

    return response.data.userState;
  } catch (e: any) {
    console.log('checkUserPhoneAsync', e.message);

    return PhoneState.ERROR;
  } finally {
    dispatch(setLoadingCheckPhone(false));
  }
};

export const loginUserAsync =
  ({ username, password }: { username: string; password: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoadingToken(true));
    dispatch(setTokenError(''));
    try {
      const response = await fetchToken(username, password);
      const parsedToken: any = parseJwt(response.access_token);
      dispatch(setUserUuid(parsedToken.userUuid));
      dispatch(setToken(response));
    } catch (e: any) {
      console.log('loginUserAsync', e.message);
      dispatch(setTokenError('Неверный пароль, повторите попытку еще раз'));
    } finally {
      dispatch(setLoadingToken(false));
    }
  };

export const refreshTokenAsync = (token: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await refreshToken(token);
    if (response) {
      const parsedToken: any = parseJwt(response.access_token);
      dispatch(setUserUuid(parsedToken.userUuid));
    }
    dispatch(setToken(response));
  } catch (e: any) {
    console.log('refreshTokenAsync', e);
    if (e.response.status === 400) {
      revokeToken();
      dispatch(setToken(null));
    }
  }
};

export const loginTinkoffIdAsync = (code: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoadingToken(true));
  try {
    const response = await fetchTokenViaCode(code, REDIRECT_ID);
    await httpClient.post(
      `user/signup/tinkoff`,
      {},
      { headers: { Authorization: `${response.token_type} ${response.access_token}` } }
    );
    const refreshResponse = await refreshToken(response.refresh_token);
    if (refreshResponse) {
      const parsedToken: any = parseJwt(refreshResponse.access_token);
      dispatch(setUserUuid(parsedToken.userUuid));
    }
    dispatch(setToken(refreshResponse));
  } catch (e: any) {
    console.log('loginTinkoffIdAsync', e.message);
  } finally {
    dispatch(setLoadingToken(false));
  }
};

export const selectProfile = (state: RootState) => state.profileData.data;
export const selectUserUuid = (state: RootState) => state.profileData.userUuid;
export const selectLoading = (state: RootState) => state.profileData.loading;
export const selectLoadingLanguage = (state: RootState) => state.profileData.loadingLanguage;
export const selectLanguage = (state: RootState) => state.profileData.language;
export const selectLanguageComment = (state: RootState) => state.profileData.comment_language;
export const selectLanguageDesc = (state: RootState) => state.profileData.desc_language;
export const selectCurrentLang = (state: RootState) => state.profileData.currentLang;
export const selectLoadingToken = (state: RootState) => state.profileData.loadingToken;
export const selectToken = (state: RootState) => state.profileData.token;
export const selectLoadingCheckPhone = (state: RootState) => state.profileData.loadingCheckPhone;
export const selectTokenError = (state: RootState) => state.profileData.tokenError;

export default profileDataSlice.reducer;
